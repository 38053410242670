import axios from "axios";
import * as jwt from "jose";

export const getJWTKey = async () => {
  if (!window.sessionStorage.getItem("jwt_public_key")) {
    const res = await axios.get(
      `${import.meta.env.VITE_SSO_URL}/api/auth/public-key`,
    );
    window.sessionStorage.setItem("jwt_public_key", res.data);
  }

  const key = window.sessionStorage.getItem("jwt_public_key");
  if (!key) throw "failed to get public key";

  return jwt.importSPKI(key, "RS512");
};
