import type { RouteRecordRaw } from "vue-router";
import { RouterView } from "vue-router";
import { i18n } from "@/plugins/i18n";

const { t } = i18n.global;

export const adminRoutes: RouteRecordRaw[] = [
  {
    path: "/admins/:adminId",
    component: () => import("./AdminLayout.vue"),
    meta: {
      breadcrumb: "admin account",
    },
    children: [
      {
        path: t("groepen"),
        component: RouterView,
        children: [
          {
            path: "",
            name: "a-groepen",
            component: () => import("./views/groepen/GroepenOverzichtView.vue"),
            meta: {
              breadcrumb: t("groepen"),
              breadcrumbTo: "a-groepen",
            },
          },
          {
            name: "a-edexml",
            path: "edexml",
            component: () => import("./views/edexml/EdexMLView.vue"),
            meta: {
              breadcrumb: "EdexML inlezen",
              breadcrumbTo: "a-edexml",
            },
          },
          {
            path: "lvsspelling",
            name: "a-lvsspelling",
            component: () =>
              import("./views/lvs-spelling/InlezenLvsSpelling.vue"),
            meta: {
              breadcrumb: "inlezen LVS-spelling",
              breadcrumbTo: "a-lvsspelling",
            },
          },
          {
            path: ":groepId",
            component: RouterView,
            meta: {
              breadcrumb: ":groep",
              breadcrumbTo: "a-leerlingen",
            },
            children: [
              {
                path: "leerlingen",
                component: RouterView,
                children: [
                  {
                    name: "a-leerlingen",
                    path: "",
                    component: () =>
                      import("./views/leerlingen/LeerlingenOverzichtView.vue"),
                    meta: {
                      breadcrumb: "leerlingen",
                      breadcrumbTo: "a-leerlingen",
                    },
                  },
                  {
                    name: "a-leerlingen-toevoegen",
                    path: "toevoegen",
                    component: () =>
                      import(
                        "./views/leerlingen-toevoegen/LeerlingenToevoegen.vue"
                      ),
                    meta: {
                      breadcrumb: "leerlingen toevoegen",
                      breadcrumbTo: "a-leerlingen-toevoegen",
                    },
                  },
                  {
                    name: "a-kind-toevoegen",
                    path: "kinderen-toevoegen",
                    component: () =>
                      import("./views/leerlingen-toevoegen/KindToevoegen.vue"),
                    meta: {
                      breadcrumb: "kinderen toevoegen",
                      breadcrumbTo: "a-kind-toevoegen",
                    },
                  },
                  {
                    name: "a-verhuistoken-inlezen",
                    path: "verhuistoken-inlezen",
                    component: () =>
                      import(
                        "./views/leerlingen-toevoegen/VerhuistokenInlezen.vue"
                      ),
                    meta: {
                      breadcrumb: "verhuistoken inlezen",
                      breadcrumbTo: "a-verhuistoken-inlezen",
                    },
                  },
                  {
                    name: "a-leerling-wachtwoorden",
                    path: "wachtwoorden",
                    component: () =>
                      import(
                        "./views/leerling-wachtwoorden/LeerlingWachtwoordenView.vue"
                      ),
                    meta: {
                      breadcrumb: "wachtwoorden",
                      breadcrumbTo: "a-leerling-wachtwoorden",
                    },
                  },
                  {
                    path: ":leerlingId",
                    component: RouterView,
                    meta: {
                      breadcrumb: ":leerling",
                    },
                    children: [
                      {
                        name: "a-leerling-oefeninstellingen",
                        path: "oefeninstellingen",
                        component: () =>
                          import(
                            "./views/groep-oefeninstellingen/LeerlingOefeninstellingenView.vue"
                          ),
                        meta: {
                          breadcrumb: "oefeninstellingen",
                          breadcrumbTo: "a-leerling-oefeninstellingen",
                        },
                      },
                      {
                        name: "a-leerling-voortgang",
                        path: "voortgang",
                        component: () =>
                          import("./views/voortgang/VoortgangLeerling.vue"),
                        meta: {
                          breadcrumb: "voortgang",
                          breadcrumbTo: "a-leerling-voortgang",
                        },
                      },
                      {
                        path: "zorgtraject",
                        name: "a-zorgtraject",
                        component: () =>
                          import(
                            "./views/zorgtraject/handelingsplannen/Handelingsplannen.vue"
                          ),
                        meta: {
                          breadcrumb: "Handelingsplannen",
                          breadcrumbTo: "a-zorgtraject",
                          restrictedTo: [
                            "superadmin",
                            "office",
                            "schooladmin",
                            "docent",
                          ],
                          featurespakket: { featureZorgtraject: true },
                        },
                      },
                      {
                        path: "zorgtraject/handelingsplanaanmaken",
                        name: "a-zorgtrajectcreate",
                        component: () =>
                          import(
                            "./views/zorgtraject/handelingsplanCreate/HandelingsplanCreate.vue"
                          ),
                        meta: {
                          crumbLabel: "Zorgtraject Aanmaken",
                          restrictedTo: [
                            "superadmin",
                            "office",
                            "schooladmin",
                            "docent",
                          ],
                          featurespakket: { featureZorgtraject: true },
                        },
                      },
                      {
                        path: "handelingsplan/:handelingsplanId",
                        name: "a-handelingsplan",
                        component: () =>
                          import(
                            "./views/zorgtraject/handelingsplan/Handelingsplan.vue"
                          ),
                        props: true,
                        meta: {
                          breadcrumb: ":handelingsplan",
                          restrictedTo: [
                            "superadmin",
                            "office",
                            "schooladmin",
                            "docent",
                          ],
                          featurespakket: { featureZorgtraject: true },
                        },
                      },
                      {
                        path: "handelingsplan/:handelingsplanId/edit",
                        name: "a-handelingsplanedit",
                        component: () =>
                          import(
                            "./views/zorgtraject/handelingsplanUpdate/HandelingsplanUpdate.vue"
                          ),
                        meta: {
                          crumbLabel: "Zorgtraject Aanmaken",
                          restrictedTo: [
                            "superadmin",
                            "office",
                            "schooladmin",
                            "docent",
                          ],
                          featurespakket: { featureZorgtraject: true },
                        },
                      },
                      {
                        path: "handelingsplan/:handelingsplanId/eindtoets",
                        name: "a-handelingsplaneindtoets",
                        component: () =>
                          import(
                            "./views/zorgtraject/eindtoets/EindtoetsCreate.vue"
                          ),
                        meta: {
                          crumbLabel: "Eindtoets toevoegen",
                          restrictedTo: [
                            "superadmin",
                            "office",
                            "schooladmin",
                            "docent",
                          ],
                          featurespakket: { featureZorgtraject: true },
                        },
                      },
                    ],
                  },
                ],
              },
              {
                path: "oefeninstellingen",
                component: RouterView,
                meta: {
                  breadcrumb: "oefeninstellingen",
                  breadcrumbTo: "a-groep-oefeninstellingen-overzicht",
                },
                children: [
                  {
                    path: "",
                    name: "a-groep-oefeninstellingen-overzicht",
                    component: () =>
                      import(
                        "./views/groep-oefeninstellingen-overzicht/OefeninstellingenOverzichtView.vue"
                      ),
                  },
                  {
                    path: ":oefeninstellingId",
                    name: "a-groep-oefeninstellingen",
                    component: () =>
                      import(
                        "./views/groep-oefeninstellingen/GroepOefeninstellingenView.vue"
                      ),
                    meta: {
                      breadcrumb: ":oefeninstelling",
                      breadcrumbTo: "a-groep-oefeninstellingen",
                    },
                  },
                ],
              },
              {
                path: "voortgang",
                name: "a-voortgang-groep",
                component: () => import("./views/voortgang/VoortgangGroep.vue"),
                meta: {
                  breadcrumb: "voortgang",
                  breadcrumbTo: "a-voortgang-groep",
                },
              },
            ],
          },
        ],
      },
      {
        path: "zorgleerlingen",
        name: "a-zorgleerlingen",
        component: () => import("./views/zorgtraject/Zorgleerlingen.vue"),
        meta: {
          breadcrumb: "zorgleerlingen",
          breadcrumbTo: "a-zorgleerlingen",
        },
      },
      {
        path: "docenten",
        name: "a-docenten",
        component: () => import("./views/docenten/DocentenOverzichtView.vue"),
        meta: {
          breadcrumb: "docenten",
          breadcrumbTo: "a-docenten",
        },
      },
      {
        path: "accountgegevens",
        name: "a-accountgegevens",
        component: () =>
          import("./views/accountgegevens/AccountgegevensView.vue"),
        meta: {
          breadcrumb: "accountgegevens",
          breadcrumbTo: "a-accountgegevens",
        },
      },
      {
        path: "licentie",
        name: "a-licentie",
        component: () => import("./views/licentie/LicentieView.vue"),
        meta: {
          breadcrumb: "licentie",
          breadcrumbTo: "a-licentie",
        },
      },
      {
        path: "berichten",
        name: "a-berichten",
        component: () => import("./views/berichten/BerichtenView.vue"),
        meta: {
          breadcrumb: "berichten",
          breadcrumbTo: "a-berichten",
        },
      },
      {
        path: "roadmap",
        name: "a-roadmap",
        component: () => import("./views/roadmap/RoadmapView.vue"),
        meta: {
          breadcrumb: "roadmap",
          breadcrumbTo: "a-roadmap",
        },
      },
      {
        path: "vriend-uitnodigen",
        name: "a-vriend-uitnodigen",
        component: () =>
          import("./views/vriend-uitnodigen/VriendUitnodigenView.vue"),
        meta: {
          breadcrumb: "vriend uitnodigen",
          breadcrumbTo: "a-vriend-uitnodigen",
        },
      },
      {
        name: "a-prullenbak",
        path: "prullenbak",
        component: () =>
          import("./views/leerlingen-prullenbak/LeerlingenPrullenbakView.vue"),
        meta: {
          breadcrumb: "prullenbak",
          breadcrumbTo: "a-prullenbak",
        },
      },
      {
        path: ":pathMatch(.*)",
        component: () => import("./NotFound.vue"),
      },
    ],
  },
];
