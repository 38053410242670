import { createApp } from "vue";

import App from "./App.vue";

import { vuetify } from "@/plugins/vuetify";
import { router } from "@/plugins/router";
import { pinia } from "@/plugins/pinia";
import { i18n } from "@/plugins/i18n";
import "@/plugins/dayjs";

import "@/plugins/axios.interceptors";

const app = createApp(App);

app.use(vuetify);
app.use(router);
app.use(pinia);
app.use(i18n);

app.mount("#app");
