import type { RouteRecordRaw } from "vue-router";

export const homeRoutes: RouteRecordRaw[] = [
  {
    path: "/",
    component: () => import("./HomeLayout.vue"),
    children: [
      // Home
      {
        name: "home",
        path: "/site/home",
        component: () => import("./views/home/HomeView.vue"),
      },
      {
        path: "/home",
        redirect: { name: "home" },
      },

      // Prijzen
      {
        name: "prijzen",
        path: "/site/prijzen",
        component: () => import("./views/prijzen/PrijzenView.vue"),
      },
      // FAQ
      {
        name: "faq",
        path: "/site/faq",
        component: () => import("./views/faq/FaqView.vue"),
      },

      // Effectiviteit
      {
        name: "effectiviteit",
        path: "/site/effectiviteit",
        component: () => import("./views/effectiviteit/EffectiviteitView.vue"),
      },

      // Aanvraagformulier
      {
        name: "aanvraagformulier",
        path: "/site/aanvraagformulier",
        component: () =>
          import("./views/aanvraagformulier/AanvraagformulierView.vue"),
      },

      // ===================
      //     Over Taalblobs
      // ===================
      {
        name: "school-rt-thuis",
        path: "/site/school-rt-thuis",
        component: () =>
          import("./views/over-taalblobs/school-rt-thuis/SchoolRtThuis.vue"),
      },
      {
        name: "adaptief",
        path: "/site/adaptief",
        component: () => import("./views/over-taalblobs/Adaptief.vue"),
      },
      {
        name: "aangepast-oefenen",
        path: "/site/aangepast-oefenen",
        component: () => import("./views/over-taalblobs/AangepastOefenen.vue"),
      },
      {
        name: "voortgang-volgen",
        path: "/site/voortgang-volgen",
        component: () => import("./views/over-taalblobs/VoortgangVolgen.vue"),
      },
      {
        name: "beloningssysteem",
        path: "/site/beloningssysteem",
        component: () => import("./views/over-taalblobs/Beloningssysteem.vue"),
      },
      {
        name: "spellen",
        path: "/site/spellen",
        component: () => import("./views/over-taalblobs/Spellen.vue"),
      },
      {
        name: "didactiek",
        path: "/site/didactiek",
        component: () => import("./views/over-taalblobs/Didactiek.vue"),
      },
      {
        name: "inzetbaarheid",
        path: "/site/inzetbaarheid",
        component: () => import("./views/over-taalblobs/Inzetbaarheid.vue"),
      },
      {
        name: "web-based",
        path: "/site/web-based",
        component: () => import("./views/over-taalblobs/WebBased.vue"),
      },
      {
        name: "werkzame-componenten",
        path: "/site/werkzame-componenten",
        component: () =>
          import("./views/over-taalblobs/WerkzameComponenten.vue"),
      },
      {
        name: "watisnodigvoorspellen",
        path: "/site/watisnodigvoorspellen",
        component: () =>
          import("./views/over-taalblobs/WatIsNodigVoorSpellen.vue"),
      },
      {
        name: "ervaringen",
        path: "/site/ervaringen",
        component: () => import("./views/over-taalblobs/Ervaringen.vue"),
      },

      // ===================
      //     Ondersteuning
      // ===================
      {
        name: "instructie",
        path: "/site/instructievideo",
        component: () =>
          import("./views/ondersteuning/instructie/InstructieView.vue"),
      },
      {
        name: "contact",
        path: "/site/contact",
        component: () =>
          import("./views/ondersteuning/contact/ContactView.vue"),
      },
      // {
      //   name: "handleiding",
      //   path: "/handleiding_taalblobs.pdf",
      // },

      // ===================
      //     Auth routes
      // ===================
      {
        name: "login",
        path: "/app/auth/login",
        component: () => import("./views/login/LoginView.vue"),
      },
      {
        name: "wachtwoordaanvragen",
        path: "/app/auth/wachtwoordaanvragen",
        component: () =>
          import("./views/wachtwoordaanvragen/WachtwoordAanvragenView.vue"),
      },
      {
        name: "wachtwoordherstellen",
        path: "/app/auth/wachtwoordherstellen/:userId/:herstelToken",
        component: () =>
          import("./views/wachtwoordherstellen/WachtwoordHerstellen.vue"),
      },
      {
        path: "/:pathMatch(.*)",
        component: () => import("./views/not-found/NotFoundView.vue"),
      },
    ],
  },
  {
    path: "",
    redirect: { name: "home" },
  },
];
