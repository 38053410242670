import type { UpdateAccountgegevensDto } from "@backend/modules/admin/dto/update-accountgegevens.dto";
import type { CredentialsDto } from "@backend/modules/auth/dto/credentials.dto";
import type { CredentialsRo } from "@backend/modules/auth/dto/credentials.ro";

import { getBaseUrl } from "@/common/get-base-url";
import { router } from "@/plugins/router";
import { defineStore } from "pinia";
import { ref } from "vue";
import axios from "axios";
import { validateToken } from "@/shared/validateToken";
import { jwtDecode } from "jwt-decode";
import type { UpdateUserAdminCredentialsDto } from "@backend/modules/user/dto/update-user-admin-credentials.dto";

interface TokenRo {
  access_token: string;
  refresh_token: string;
}

export type User = {
  _id: string;
  adminId: string;
  username: string;
  role: string;
  ip: string;
  exp: number;
  iat: number;
  licentie: {
    geblokkeerd: {
      _id: string;
      aantalMax: false;
      administrator: false;
      betalingsachterstand: false;
    };
  };
};

const LOCAL_TOKEN_STORAGE_KEY = "access_token";
const LOCAL_REFRESH_TOKEN_STORAGE_KEY = "refresh_token";
const LOCAL_USER_KEY = "user";
const LOCAL_USING_SSO_KEY = "usingSSO";

export const useAuthStore = defineStore("auth", () => {
  // === variables ===============================================================
  const user = ref<User | null>(null);
  const usingSSO = ref(localStorage.getItem(LOCAL_USING_SSO_KEY) === "true");

  const storedUser = localStorage.getItem(LOCAL_USER_KEY);
  try {
    if (storedUser) user.value = JSON.parse(storedUser);
  } catch {
    console.log("invalid user detected");
  }

  if (
    user.value?.exp &&
    user.value.exp < Math.floor(new Date().getTime() / 1000)
  ) {
    removeUser();
  }

  // === functions ===============================================================
  function token() {
    return getTokenStorage().getItem(LOCAL_TOKEN_STORAGE_KEY);
  }

  async function login(credentials: CredentialsDto): Promise<void> {
    setUsingSSO(false);
    const { data } = await axios.post<CredentialsRo>(
      `${getBaseUrl()}/auth`,
      credentials,
    );

    const { token } = data;
    const user: User = jwtDecode(token);

    updateToken(token, user);

    await handleLogin();
  }

  async function logout(redirect = true): Promise<void> {
    removeUser();
    if (redirect) {
      location.replace("/app/auth/login");
    }
  }

  function removeUser(): void {
    getTokenStorage().removeItem(LOCAL_TOKEN_STORAGE_KEY);
    getTokenStorage().removeItem(LOCAL_REFRESH_TOKEN_STORAGE_KEY);
    setUser(null);

    setUsingSSO(false);
  }

  async function updateCredentials(
    dto: UpdateUserAdminCredentialsDto,
  ): Promise<void> {
    await axios.patch(
      `${getBaseUrl()}/users/${user.value?._id}/credentials`,
      dto,
    );
  }
  async function updateAccountgegevens(
    adminId: string,
    dto: UpdateAccountgegevensDto,
  ): Promise<void> {
    await axios.patch(`${getBaseUrl()}/admins/${adminId}/accountgegevens`, dto);
  }

  async function getTokenFromCode(
    auth_code: string,
    code_verifier: string,
  ): Promise<Error | null> {
    setUsingSSO(true);
    const res = await axios.get<TokenRo>(
      `${import.meta.env.VITE_SSO_URL}/api/auth/token`,
      {
        params: {
          auth_code,
          code_verifier,
        },
      },
    );

    try {
      await validateToken(res.data.access_token);
      await validateToken(res.data.refresh_token);

      getTokenStorage().setItem(LOCAL_TOKEN_STORAGE_KEY, res.data.access_token);
      getTokenStorage().setItem(
        LOCAL_REFRESH_TOKEN_STORAGE_KEY,
        res.data.refresh_token,
      );
    } catch (e: any) {
      console.error("failed to verify token", e);
      return e;
    }

    return null;
  }

  async function refreshTokens() {
    const refreshToken = getTokenStorage().getItem(
      LOCAL_REFRESH_TOKEN_STORAGE_KEY,
    );
    if (!refreshToken) return false;

    const { data } = await axios.get<TokenRo>(
      `${import.meta.env.VITE_SSO_URL}/api/auth/token/refresh`,
      { headers: { Authorization: `Bearer ${refreshToken}` } },
    );

    try {
      await validateToken(data.access_token);
      await validateToken(data.refresh_token);

      getTokenStorage().setItem("access_token", data.access_token);
      getTokenStorage().setItem("refresh_token", data.refresh_token);
    } catch (e: any) {
      console.error("failed to verify token", e);
      return false;
    }

    return true;
  }

  async function loginSSO() {
    setUsingSSO(true);
    const { data } = await axios.post<User>(`${getBaseUrl()}/auth/sso`);

    setUser(data);

    await handleLogin();
  }

  // === helper functions ========================================================
  function getTokenStorage(): Storage {
    return usingSSO.value ? sessionStorage : localStorage;
  }

  function updateToken(newToken: string, userInfo: User): void {
    getTokenStorage().setItem(LOCAL_TOKEN_STORAGE_KEY, newToken);
    setUser(userInfo);
  }

  function setUser(newUser: User | null) {
    user.value = newUser;
    if (newUser) localStorage.setItem(LOCAL_USER_KEY, JSON.stringify(newUser));
    else localStorage.removeItem(LOCAL_USER_KEY);
  }

  function setUsingSSO(newValue: boolean) {
    usingSSO.value = newValue;
    localStorage.setItem(LOCAL_USING_SSO_KEY, newValue ? "true" : "false");
  }

  async function handleLogin() {
    if (user.value == null) return;

    const wantedRoute = sessionStorage.getItem("wantedRoute");
    if (wantedRoute) {
      sessionStorage.removeItem("wantedRoute");
      await router.push({ path: wantedRoute });
      return;
    }

    // Leerling
    if (user.value.role === "leerling" || user.value.role === "demo") {
      await router.push({
        name: "l-landkaart",
        params: {
          leerlingId: user.value._id,
        },
      });
      return;
    }

    // Schooladmin en docent
    if (user.value.role === "schooladmin" || user.value.role === "docent") {
      await router.push({
        name: "a-groepen",
        params: {
          adminId: user.value.adminId,
        },
      });
      return;
    }

    // Superadmin
    if (user.value.role === "office" || user.value.role === "superadmin") {
      await router.push({ name: "o-dashboard" });
      return;
    }
  }

  return {
    token,
    user,
    usingSSO,
    login,
    logout,
    updateCredentials,
    updateAccountgegevens,
    getTokenFromCode,
    loginSSO,
    refreshTokens,
  };
});
