import { createRouter, createWebHistory } from "vue-router";
import { homeRoutes } from "@/modules/public/home.routes";
import { adminRoutes } from "@/modules/admin/admin.routes";
import { officeRoutes } from "@/modules/office/office.routes";
import { leerlingRoutes } from "@/modules/leerling/leerling.routes";

export const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  scrollBehavior: () => ({ top: 0 }),
  routes: [...officeRoutes, ...adminRoutes, ...leerlingRoutes, ...homeRoutes],
});
