import * as jwt from "jose";
import { getJWTKey } from "./getJWTKey";

export const validateToken = async (token?: string | null) => {
  const JWTKey = await getJWTKey();

  if (!token) token = window.sessionStorage.getItem("access_token");
  if (!token) throw "no token detected";
  const content = await jwt.jwtVerify<{ expires_in: string }>(token, JWTKey);

  if (
    !content.payload.expires_in ||
    new Date(content.payload.expires_in).getTime() <= new Date().getTime()
  )
    throw "token expired";

  return content.payload;
};
