import type { AxiosError, InternalAxiosRequestConfig } from "axios";
import axios from "axios";

import { useAuthStore } from "@/store/auth/auth.store";
import { router } from "@/plugins/router";

axios.interceptors.request.use(
  async (
    requestConfig: InternalAxiosRequestConfig & {
      interceptor_config?: string;
    },
  ) => {
    if (
      requestConfig.interceptor_config === "NO_RETRY" ||
      !requestConfig.headers.Authorization
    ) {
      const { token } = useAuthStore();

      requestConfig.headers.Authorization = `Bearer ${token()}`;
    }

    return requestConfig;
  },
  (error: AxiosError) => {
    return Promise.reject(error);
  },
  {
    runWhen: (config) => {
      let path: string;
      try {
        path = new URL(config.url || "").pathname;
      } catch {
        path = config.url || "";
      }
      return path.startsWith(`api`) || path.startsWith(`/api`);
    },
  },
);

axios.interceptors.response.use(
  undefined,
  async (
    error: AxiosError & {
      config: InternalAxiosRequestConfig & { interceptor_config?: string };
    },
  ) => {
    if (error?.message) {
      if (error.response?.status === 401 || error.response?.status === 403) {
        const store = useAuthStore();

        if (
          error.config &&
          error.config.interceptor_config !== "NO_RETRY" &&
          store.usingSSO
        ) {
          if (await store.refreshTokens()) {
            try {
              return await axios.request({
                ...error.config,
                interceptor_config: "NO_RETRY",
              } as InternalAxiosRequestConfig);
            } catch {
              console.error("request failed permanently, login needed");
            }
          }
        }

        const routeToSSO = store.usingSSO;

        window.sessionStorage.setItem(
          "wantedRoute",
          router.currentRoute.value.fullPath,
        );

        await store.logout(false);
        if (router.currentRoute.value.name !== "login") {
          await router.push({
            name: routeToSSO ? "login-sso" : "login",
          });
        }
      }

      // Error message van API mappen naar AxiosError
      if (error.response?.data) {
        error.message = (error.response.data as Error).message;
      }
    }

    return Promise.reject(error);
  },
);
