import "@/styles/variables.scss";

import { aliases, mdi } from "vuetify/iconsets/mdi-svg";
import { createVuetify } from "vuetify";
import { VTimePicker } from "vuetify/labs/components";
import { nl } from "vuetify/locale";

export const vuetify = createVuetify({
  locale: {
    locale: "nl",
    messages: { nl },
  },
  defaults: {
    VBtn: { variant: "flat" },
    VTextField: { variant: "outlined", color: "primary", density: "compact" },
    VTextarea: { variant: "outlined", color: "primary", density: "compact" },
    VSelect: { variant: "outlined", density: "compact", color: "primary" },
    VAlert: { variant: "tonal" },
    VDataTable: { mobileBreakpoint: -1 },
    VStepperItem: { color: "primary" },
    VCheckbox: { color: "primary" },
  },
  icons: {
    defaultSet: "mdi",
    aliases,
    sets: {
      mdi,
    },
  },
  components: {
    VTimePicker,
  },
});
