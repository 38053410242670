import type { RouteRecordRaw } from "vue-router";

export const officeRoutes: RouteRecordRaw[] = [
  {
    path: "/superadmin",
    component: () => import("./OfficeLayout.vue"),
    meta: {
      breadcrumb: "office account",
    },
    children: [
      {
        name: "o-dashboard",
        path: "dashboard",
        component: () => import("./views/dashboard/DashboardView.vue"),
        meta: {
          breadcrumb: "dashboard",
          breadcrumbTo: "o-dashboard",
        },
      },
      {
        name: "o-admins",
        path: "admins",
        component: () => import("./views/admins/OfficeAdminsView.vue"),
        meta: {
          breadcrumb: "admins",
          breadcrumbTo: "o-admins",
        },
      },
      {
        path: "admins/:adminId/",
        component: () => import("./views/admin/Admin.vue"),
        meta: {
          breadcrumb: "admins",
          breadcrumbTo: "o-admins",
        },
        children: [
          {
            name: "o-accountgegevens",
            path: "accountgegevens",
            component: () =>
              import("./views/admin/accountgegevens/Accountgegevens.vue"),
            meta: {
              breadcrumb: "accountgegevens",
              breadcrumbTo: "o-accountgegevens",
            },
          },
          {
            name: "o-verhuis-admin",
            path: "verhuis-admin",
            component: () =>
              import("./views/admin/verhuis-admin/VerhuisAdmin.vue"),
            meta: {
              breadcrumb: "verhuis-admin",
              breadcrumbTo: "o-verhuis-admin",
            },
          },
          {
            name: "o-licentie",
            path: "licentie",
            component: () => import("./views/admin/licentie/Licentie.vue"),
            meta: {
              breadcrumb: "licentie",
              breadcrumbTo: "o-licentie",
            },
          },
        ],
      },
      {
        name: "o-admin-aanmaken",
        path: "aanmaken",
        component: () => import("./views/admin-aanmaken/AdminAanmakenView.vue"),
        meta: {
          breadcrumb: "admin aanmaken",
          breadcrumbTo: "o-admin-aanmaken",
        },
      },
      {
        name: "o-leerlingen",
        path: "leerlingen",
        component: () => import("./views/leerlingen/OfficeLeerlingenView.vue"),
        meta: {
          breadcrumb: "leerlingen",
          breadcrumbTo: "o-leerlingen",
        },
      },
      {
        name: "o-uitnodiging-versturen",
        path: "uitnodiging-versturen",
        component: () =>
          import("./views/uitnodiging-versturen/UitnodigingVersturen.vue"),
        meta: {
          breadcrumb: "uitnodiging versturen",
          breadcrumbTo: "o-uitnodiging-versturen",
        },
      },
      {
        path: "webinars",
        name: "o-webinar",
        component: () =>
          import("@/modules/office/views/webinar/WebinarView.vue"),
        meta: { breadcrumb: "webinars", breadcrumbTo: "o-webinar" },
      },
      {
        name: "o-berichten",
        path: "berichten",
        component: () => import("./views/berichten/BerichtenView.vue"),
        meta: {
          breadcrumb: "berichten",
          breadcrumbTo: "o-berichten",
        },
      },
      {
        name: "o-mailtemplates",
        path: "mailtemplates",
        component: () => import("./views/mailtemplates/Mailtemplates.vue"),
        meta: {
          breadcrumb: "mailtemplates",
          breadcrumbTo: "o-mailtemplates",
        },
      },
      {
        path: ":pathMatch(.*)",
        component: () => import("./NotFound.vue"),
      },
    ],
  },
];
