import { createI18n } from "vue-i18n";
import type { Locale } from "@/composables/use-translate";
import { isVlaams } from "@/utils/isVlaams";

const locale = isVlaams() ? "be" : "nl";

const nl: Locale = {
  groep: "groep",
  Groep: "Groep",
  normgroep: "normgroep",
  "groep n": "groep {0}",
  "groep n en n": "groep {0} en {1}",
  "groep n t/m n": "groep {0} t/m {1}",
  "groep n tm groep n": "groep {0} tot groep {1}",
  "begin tot eind": "van begin groep {0} tot eind groep {1}",
  groepen: "groepen",
  Groepen: "Groepen",
  groepsindeling: "groepsindeling",
  rt: "RT",
  rtPraktijk: "RT-praktijk",
  extension: "nl",
};

const be: Locale = {
  groep: "klas",
  Groep: "Klas",
  normgroep: "normklas",
  "groep n": "klas {0}",
  "groep n en n": "klas {0} en {1}",
  "groep n t/m n": "klas {0} t/m {1}",
  "groep n tm groep n": "klas {0} tot en met klas {1}",
  "begin tot eind": "van begin klas {0} tot eind klas {1}",
  groepen: "klassen",
  Groepen: "Klassen",
  groepsindeling: "klasindeling",
  rt: "logopedie",
  rtPraktijk: "logopedie",
  extension: "be",
};

export const i18n = createI18n({
  legacy: false,
  locale,
  fallbackLocale: "nl",
  messages: { nl, be },
});
