import { RouterView } from "vue-router";

export const leerlingRoutes = [
  // {
  //   path: "/testspel",
  //   name: "l-testspel",
  //   component: () => import("@/modules/leerling/views/testspel/Testspel.vue"),
  // },
  //
  // {
  //   path: "/leerling/:leerlingId/instructievideo/:taakId",
  //   name: "l-instructievideos",
  //   component: () =>
  //     import("@/modules/leerling/views/instructievideo/InstructieVideo.vue"),
  //   meta: {
  //     restrictedTo: [
  //       "superadmin",
  //       "schooladmin",
  //       "rtadmin",
  //       "thuisadmin",
  //       "office",
  //       "docent",
  //       "leerling",
  //       "demo",
  //     ],
  //   },
  // },

  {
    path: "/leerling/:leerlingId",
    component: () => import("./LeerlingHome.vue"),
    meta: {
      crumbLabel: "leerling",
      restrictedTo: [
        "superadmin",
        "schooladmin",
        "rtadmin",
        "thuisadmin",
        "office",
        "docent",
        "leerling",
        "demo",
      ],
    },
    children: [
      {
        path: "landkaart",
        name: "l-landkaart",
        component: () =>
          import("@/modules/leerling/views/landkaart/Landkaart.vue"),
        meta: {
          restrictedTo: [
            "superadmin",
            "schooladmin",
            "rtadmin",
            "thuisadmin",
            "office",
            "docent",
            "leerling",
            "demo",
          ],
        },
      },
      {
        path: `admins/:adminId/methodetoets/:methodetoetsId`,
        name: "l-methodetoets",
        component: () =>
          import("@/modules/leerling/views/methodetoets/Methodetoets.vue"),

        features: { testschool: true },
      },
      {
        path: "somvensters/:somvensterId",
        component: RouterView,
        children: [
          {
            path: "taken/:taakId/spellen/pizzabakken",
            name: "l-pizzabakken",
            component: () =>
              import("@/modules/leerling/views/pizzabakken/Pizzabakken.vue"),
          },
          {
            path: "taken/:taakId/spellen/treinmachinist",
            name: "l-treinmachinist",
            component: () =>
              import(
                "@/modules/leerling/views/treinmachinist/Treinmachinist.vue"
              ),
          },
          {
            path: "taken/:taakId/spellen/glazenwasser",
            name: "l-glazenwasser",
            component: () =>
              import("@/modules/leerling/views/glazenwasser/Glazenwasser.vue"),
          },
          {
            path: "taken/:taakId/spellen/taxirit",
            name: "l-taxirit",
            component: () =>
              import("@/modules/leerling/views/taxirit/Taxirit.vue"),
          },
          {
            path: "taken/:taakId/spellen/pijplijn",
            name: "l-pijplijn",
            component: () =>
              import("@/modules/leerling/views/pijplijn/Pijplijn.vue"),
          },
          {
            path: "taken/:taakId/spellen/verfgooien",
            name: "l-verfgooien",
            component: () =>
              import("@/modules/leerling/views/verfgooien/Verfgooien.vue"),
          },
          {
            path: "taken/:taakId/spellen/botenkoers",
            name: "l-botenkoers",
            component: () =>
              import("@/modules/leerling/views/botenkoers/Botenkoers.vue"),
          },
          {
            path: "taken/:taakId/spellen/wortelboer",
            name: "l-wortelboer",
            component: () =>
              import("@/modules/leerling/views/wortelboer/Wortelboer.vue"),
          },
          {
            path: "taken/:taakId/spellen/tuinsproeien",
            name: "l-tuinsproeien",
            component: () =>
              import("@/modules/leerling/views/tuinsproeien/Tuinsproeien.vue"),
          },
          {
            path: "taken/:taakId/spellen/ninja",
            name: "l-ninja",
            component: () => import("@/modules/leerling/views/ninja/Ninja.vue"),
          },
          {
            path: "taken/:taakId/spellen/piñata",
            name: "l-piñata",
            component: () =>
              import("@/modules/leerling/views/piñata/Piñata.vue"),
          },
          //{path: 'taken/:taakId/spellen/sneeuwballengevecht', name: 'l-sneeuwballengevecht', component: () => import( '@/modules/leerling/views/sneeuwballengevecht/Sneeuwballengevecht.vue'), meta: {restrictedTo: ['superadmin', 'schooladmin', 'rtadmin', 'thuisadmin', 'office', 'docent', 'leerling']}},
          {
            path: "taken/:taakId/spellen/postbezorger",
            name: "l-postbezorger",
            component: () =>
              import("@/modules/leerling/views/postbezorger/Postbezorger.vue"),
          },
          {
            path: "taken/:taakId/spellen/stofzuigen",
            name: "l-stofzuigen",
            component: () =>
              import("@/modules/leerling/views/stofzuigen/Stofzuigen.vue"),
          },
          {
            path: "taken/:taakId/spellen/dinograven",
            name: "l-dinograven",
            component: () =>
              import("@/modules/leerling/views/dinograven/Dinograven.vue"),
          },
          {
            path: "taken/:taakId/spellen/buurtsuper",
            name: "l-buurtsuper",
            component: () =>
              import("@/modules/leerling/views/buurtsuper/Buurtsuper.vue"),
          },
          {
            path: "taken/:taakId/spellen/bladblazen",
            name: "l-bladblazen",
            component: () =>
              import("@/modules/leerling/views/bladblazen/Bladblazen.vue"),
          },
          {
            path: "taken/:taakId/spellen/bioscoop2",
            name: "l-bioscoop2",
            component: () =>
              import("@/modules/leerling/views/bioscoop/Bioscoop.vue"),
          },
          {
            path: "taken/:taakId/spellen/ballengooien",
            name: "l-ballengooien",
            component: () =>
              import("@/modules/leerling/views/ballengooien/Ballengooien.vue"),
          },
          {
            path: "taken/:taakId/spellen/blobgezocht",
            name: "l-blobgezocht",
            component: () =>
              import("@/modules/leerling/views/blobgezocht/Blobgezocht.vue"),
          },
          {
            path: "taken/:taakId/spellen/groeivis",
            name: "l-groeivis",
            component: () =>
              import("@/modules/leerling/views/groeivis/Groeivis.vue"),
          },
          {
            path: "taken/:taakId/spellen/dozenspringen",
            name: "l-dozenspringen",
            component: () =>
              import(
                "@/modules/leerling/views/dozenspringen/Dozenspringen.vue"
              ),
          },
          {
            path: "taken/:taakId/spellen/doolhof",
            name: "l-doolhof",
            component: () =>
              import("@/modules/leerling/views/doolhof/Doolhof.vue"),
          },
          {
            path: "taken/:taakId/spellen/platformklimmen",
            name: "l-platformklimmen",
            component: () =>
              import(
                "@/modules/leerling/views/platformklimmen/Platformklimmen.vue"
              ),
          },
          {
            path: "taken/:taakId/spellen/fotosafari",
            name: "l-fotosafari",
            component: () =>
              import("@/modules/leerling/views/fotosafari/Fotosafari.vue"),
          },
          {
            path: "taken/:taakId/spellen/kippenspuiten",
            name: "l-kippenspuiten",
            component: () =>
              import(
                "@/modules/leerling/views/kippenspuiten/Kippenspuiten.vue"
              ),
          },
          {
            path: "taken/:taakId/spellen/stuitergame",
            name: "l-stuitergame",
            component: () =>
              import("@/modules/leerling/views/stuitergame/Stuitergame.vue"),
          },
          {
            path: "taken/:taakId/spellen/rups",
            name: "l-rups",
            component: () => import("@/modules/leerling/views/rups/Rups.vue"),
          },
          {
            path: "taken/:taakId/spellen/paragame",
            name: "l-paragame",
            component: () =>
              import("@/modules/leerling/views/paragame/Paragame.vue"),
          },
          {
            path: "taken/:taakId/spellen/vissenvangen",
            name: "l-vissenvangen",
            component: () =>
              import("@/modules/leerling/views/vissenvangen/Vissenvangen.vue"),
          },
          {
            path: "taken/:taakId/spellen/flipperkast",
            name: "l-flipperkast",
            component: () =>
              import("@/modules/leerling/views/flipperkast/Flipperkast.vue"),
          },
          {
            path: "taken/:taakId/spellen/splitsmachine",
            name: "l-splitsmachine",
            component: () =>
              import(
                "@/modules/leerling/views/splitsplatform/Splitsplatform.vue"
              ),
          },
          {
            path: "taken/:taakId/spellen/visvijver",
            name: "l-visvijver",
            component: () =>
              import("@/modules/leerling/views/visvijver/Visvijver.vue"),
          },
          {
            path: "taken/:taakId/spellen/quizvraag",
            name: "l-quizvraag",
            component: () =>
              import("@/modules/leerling/views/quizvraag/Quizvraag.vue"),
          },
          {
            path: "taken/:taakId/spellen/roeien",
            name: "l-roeien",
            component: () =>
              import("@/modules/leerling/views/roeien/Roeien.vue"),
          },
          {
            path: "taken/:taakId/spellen/sneeuwkanon",
            name: "l-sneeuwkanon",
            component: () =>
              import("@/modules/leerling/views/sneeuwkanon/Sneeuwkanon.vue"),
          },
          {
            path: "taken/:taakId/spellen/leesrace",
            name: "l-leesrace",
            component: () =>
              import("@/modules/leerling/views/leesrace/Leesrace.vue"),
          },
          {
            path: "taken/:taakId/spellen/leesraket",
            name: "l-leesraket",
            component: () =>
              import("@/modules/leerling/views/leesraket/Leesraket.vue"),
          },
          {
            path: "taken/:taakId/spellen/helikopter",
            name: "l-helikopter",
            component: () =>
              import("@/modules/leerling/views/helikopter/Helikopter.vue"),
          },
          {
            path: "taken/:taakId/spellen/vishaak",
            name: "l-vishaak",
            component: () =>
              import("@/modules/leerling/views/vishaak/Vishaak.vue"),
          },
          {
            path: "taken/:taakId/spellen/vliegrace",
            name: "l-vliegrace",
            component: () =>
              import("@/modules/leerling/views/vogelvliegen/Vogelvliegen.vue"),
          },
          // {
          //   path: "taken/:taakId/spellen/vliegrace2",
          //   name: "l-vliegrace2",
          //   component: () => import("@/modules/leerling/views/vliegrace/Vliegrace.vue"),
          //   meta: { restrictedTo: ["superadmin", "schooladmin", "rtadmin", "thuisadmin", "office", "docent", "leerling", "demo"] },
          // },
          {
            path: "taken/:taakId/spellen/beestjesvangen",
            name: "l-beestjesvangen",
            component: () =>
              import(
                "@/modules/leerling/views/beestjesvangen/Beestjesvangen.vue"
              ),
          },
          {
            path: "taken/:taakId/spellen/raketstrijd",
            name: "l-raketstrijd",
            component: () =>
              import("@/modules/leerling/views/raketstrijd/Raketstrijd.vue"),
          },
          {
            path: "taken/:taakId/spellen/bellenprikken",
            name: "l-bellenprikken",
            component: () =>
              import(
                "@/modules/leerling/views/bellenprikken/Bellenprikken.vue"
              ),
          },
          {
            path: "taken/:taakId/spellen/autorace",
            name: "l-autorace",
            component: () =>
              import("@/modules/leerling/views/autorace/Autorace.vue"),
          },
          {
            path: "taken/:taakId/spellen/dienbladbalanceren",
            name: "l-dienbladbalanceren",
            component: () =>
              import(
                "@/modules/leerling/views/dienbladbalanceren/Dienbladbalanceren.vue"
              ),
          },
        ],
      },
      //{ path: '*', redirect: {name: 'login'}}
    ],
  },
];
